<template>
  <el-alert
    title="Notice"
    type="info"
    :description=statusInfo
    :closable="false"
  />
  <el-table stripe style="width: 90%; left:5%" height="850" :data="comments">
        <el-table-column 
            v-for="(title,index) in tableData"
             :prop="title.prop"
             :label="title.name"
             :key="title.prop"
             :width="index==200"
             :index="title.index">
             
        </el-table-column>
        <el-table-column fixed="right" label="Detail">
            <template #default="scope">
                <div style="display: flex">
        <router-link
          :to="{
            path: '/article',
            query: {
              id: scope.row.articleall[0].id,
              name: scope.row.articleall[0].name,
              source: scope.row.articleall[0].source,
              topic: scope.row.articleall[0].topic,
              category: scope.row.articleall[0].category,
              readingTimes: scope.row.articleall[0].readingTimes,
              link: scope.row.articleall[0].link,
            },
          }"
        >link {{ scope.row.articleall.id }}</router-link>
                </div>
                </template>
        </el-table-column>
  </el-table>
  </template>
  
  <script>
  import {ref} from "vue";
  import {useRoute} from "vue-router";
  import {formatDateTime} from "@/utils/dateFormatter";
  import {get, post} from "@/api";
  import {useRouter} from "vue-router";
  export default {
    name: "ProposedComments",
    setup() {
      const activities = ref(0);
      const route = useRoute();
      const names = ref([]);
      const router = useRouter()
      const statusInfo = ref("")
      const uid = ref(-1)
      const uname = ref("")
      const user = ref("")
      const tableData = [
            {prop:"timeAdded",
            name:"TimeAdded"},
            {prop: "article",
            name:"Article"},
            {prop:"content",
            name:"Comment"},
            {prop:"status",
            name:"Status"},
        ]
      const comments = ref([])
      const comment = ref("")
      
  
      function dateFormat(date) {
        let old = new Date(date)
        return formatDateTime(old, 'yyyy-MM-dd HH:mm:ss')
      }
  
      function load() {
        if (sessionStorage.getItem("token")) {
          post(`/user/logged`).then(res => {
          if (res.res == 1) {
            user.value = res.user
            uid.value = res.user.uid
            uname.value = res.user.uname

            if(user.value.commentAuthority == 0) {
              statusInfo.value = "You're not allowed to comment until you're moderated"
            } else {
              statusInfo.value = "You can comment whenever you want!"
            }

            get('/comment/user-id/'+ uid.value).then((res) => {
              comments.value = res.comment
              for(let i in comments.value) {
                get('/article/id/'+comments.value[i].articleId).then(res => {
                  comments.value[i].article = res.name
                })
                let old = new Date(comments.value[i].timeAdded)
                comments.value[i].timeAdded = formatDateTime(old, 'yyyy-MM-dd HH:mm:ss')
                if(comments.value[i].status==1) {
                  comments.value[i].status = "approved"
                }else {
                  comments.value[i].status = "pending"
                }
                get('/article/id/all/'+comments.value[i].articleId).then(res => {
                  comments.value[i].articleall = res.article
                })
              }
              console.log(comments.value)
            })
          } else {
            sessionStorage.removeItem("token")
          }
        })
    }
      }
      load()
  
      return{
        activities,
        names,
        dateFormat,
        tableData,
        statusInfo,
        comment,
        comments
      }
    }
  }
  </script>
  
  <style scoped>
  </style>